import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { roadmapsRequests } from '@src/api/roadmaps'
import {
  departmentColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  teamColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { TableWidget, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { useShowRoadmapDetails, useRoadmapContext, useRoadmapRefresh } from './RoadmapTab'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { GoalsSettingsButton } from '../components/GoalsSettingsButton'
import { EntitySelector } from './EntitySelector'
import { FormattedMessage } from 'react-intl'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
      width: 350,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    {
      ...teamColumn,
      width: 150,
    },
    {
      ...departmentColumn,
      width: 150,
    },
    {
      ...roadmapProgressColumn,
      width: 120,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapStartDate,
      width: 100,
    },
    {
      ...roadmapDueDate,
      width: 100,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 100,
    },
    {
      ...ownerNameColumn,
      width: 120,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const PerformanceRoadmaps = ({
  initialCycle,
}: {
  initialCycle: ReviewCyclesInterface
}) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled

  const initialFilter = [
    ...getCommonFilters({ reviewCycle: initialCycle }),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(roadmapsRequests, initialFilter)

  const { renderCount } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const cycleID = table.filterBy.find(filter => filter.columnName === 'review_cycle__id')
    ?.filters[0].id

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  const filterType = useMemo(() => {
    const typeFilter = table.filterBy.find(
      ({ columnName }) => columnName === 'type',
    )?.filters
    switch (typeFilter?.[0].id) {
      case 'department_roadmap':
        return 'department'
      case 'team_roadmap':
        return 'team'
      default:
        return null
    }
  }, [table.filterBy])

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
          />
          <OverallProgress value={table?.stats?.avg_done} />
        </TableWidget.Info>
        <TableWidget.Actions>
          <MoreBar>
            {/* @TODO DISPLAY BASED ON PERMISSIONS https://revolut.atlassian.net/browse/REVC-6323 
            we don't have permissions at this level to know whether this button should be displayed
            if user do not have permission it will display relevant message on trying to create */}
            <MoreBar.Action
              useIcon="Plus"
              onClick={() =>
                navigateTo(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, {
                  roadmapSelectCycleID: cycleID,
                })
              }
            >
              <FormattedMessage
                id="performance.roadmaps.create"
                defaultMessage="Create roadmap"
              />
            </MoreBar.Action>
            <GoalsSettingsButton />
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          <EntitySelector
            cycleFilter={table.filterBy.find(f => f.columnName === 'review_cycle__id')}
            onFilterChange={table.onFilterChange}
          />
        </TableWidget.Filters>
        <TableWidget.Table>
          <AdjustableTable<RoadmapInterface>
            name={TableNames.CompanyRoadmap}
            useWindowScroll
            hiddenCells={{
              [teamColumn.idPoint]: filterType !== 'team',
              [departmentColumn.idPoint]: filterType !== 'department',
            }}
            row={ROW}
            onRowClick={showRoadmapDetails}
            {...table}
            dataType={ctx.dataType}
            pendingDataType={ctx.isLoading}
            noDataMessage={ctx.noDataMessage}
            expandableType="chevron"
            fetchChildren={(parentIndexes, id) => table.fetchChildren(parentIndexes, id)}
            renderCount={
              table?.stats?.refresh_date_time
                ? count => renderCount(count, table?.stats?.refresh_date_time!)
                : undefined
            }
          />
        </TableWidget.Table>
      </TableWidget>
      {renderSidebar({ canEdit: false })}
    </>
  )
}
